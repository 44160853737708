import React, { Fragment, useEffect } from 'react';
import { observer, Observer, useLocalObservable } from 'mobx-react-lite';
import { Dropdown, LoadingSpinner, Error } from 'components/UI';
import {
  ClearIcon,
  Divider,
  FilterIcon,
  HeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableWrapper,
} from 'styles/styledComponents';
import Moment from 'moment/moment';
import { _get, _includes, _toNumber, _toPairs, useInjectStores } from 'utils/utils';

const { default: styled } = require('styled-components');

const Insufficient = observer((props) => {
  const { filterStore, utilsStore, widgetStore } = useInjectStores();
  const toCurrency = utilsStore.toCurrency;
  const widgetModel = widgetStore.cardExpHistDetails;
  const store = useLocalObservable(() => ({
    widgetData: null,
    currentLocalFilter: '',
    totOppCnt: 0,
    totOppAmt: 0,
    //
    dnhAmtRatio: 0,
    totDnhAmt: 0,
    totDnhCnt: 0,
    //
    fixAmtRatio: 0,
    totFixCnt: 0,
    totFixAmt: 0,
    //
    totRedoCnt: 0,
    totRedoAmt: 0,
    redoAmtRatio: 0,
    //
    sdAmtRatio: 0,
    totSdCnt: 0,
    totSdAmt: 0,
    //
    setStatus(status) {
      this.status = status;
    },
    setData(data) {
      this.widgetData = data.values[0];
    },
    setParams() {
      this.totOppCnt = this.widgetData.tot_opp_cnt;
      this.totOppAmt = this.widgetData.tot_opp_amt;
      this.dnhAmtRatio = this.widgetData.dnh_amt_ratio;
      this.totDnhAmt = this.widgetData.tot_dnh_amt;
      this.totDnhCnt = this.widgetData.tot_dnh_cnt;
      this.fixAmtRatio = this.widgetData.fix_amt_ratio;
      this.totFixCnt = this.widgetData.tot_fix_cnt;
      this.totFixAmt = this.widgetData.tot_fix_amt;
      this.totRedoCnt = this.widgetData.tot_redo_cnt;
      this.totRedoAmt = this.widgetData.tot_redo_amt;
      this.redoAmtRatio = this.widgetData.redo_amt_ratio;
      this.sdAmtRatio = this.widgetData.sd_amt_ratio;
      this.totSdCnt = this.widgetData.tot_sd_cnt;
      this.totSdAmt = this.widgetData.tot_sd_amt;
    },
  }));

  useEffect(() => {
    if (props.data) {
      store.setData(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    store.setStatus(props.status);
  }, [props.status]);

  useEffect(() => {
    if (props.data) {
      store.setParams(props.data);
    }
  }, [props.data]);
  return (
    <Observer>
      {() => (
        <Wrapper margin={props.margin}>
          <>
            {store.status === 'loading' ? (
              <LoadingSpinner size={'200px'} />
            ) : store.status === 'error' ? (
              <Error error={props.error} />
            ) : (
              <TableWrapper height="100%">
                {/* <Dropdown
                  id="historical"
                  options={store.dropdownOptions}
                  value={widgetModel.date}
                  onChange={store.selectOption}
                  placeholder="Select Month"
                  width={'140px'}
                  height={'20px'}
                /> */}
                <TableHeader>Insufficient Funds (SD)</TableHeader>

                <Divider />

                <Table>
                  <TableBody>
                    <Fragment>
                      <TableRow>
                        <TableCell>{store.totSdCnt}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{toCurrency(store.totSdAmt)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{store.sdAmtRatio}</TableCell>
                      </TableRow>
                    </Fragment>
                  </TableBody>
                </Table>
              </TableWrapper>
            )}
          </>
        </Wrapper>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 317px;
  .center {
    color: ${({ theme }) => theme.colors.text};
    margin: auto;
  }
`;

export default Insufficient;
