import React, { Fragment, useState, useEffect } from 'react';
/* state management */
import { Observer, observer, useLocalObservable } from 'mobx-react-lite';
/* styles */
import styled from 'styled-components/macro';
import { Item, Label, SortableLinkItem, SortableListWrapper, SubItem } from './StyledSidNav';
/* utils */
import { _get, _includes, _toUpper, _reduce, _sortBy, useInjectStores } from 'utils/utils';
import { Link, useLocation } from 'react-router-dom';
import { ArrowCircleLeftIcon } from 'styles/styledComponents';
import { LinkExternalIcon } from 'styles/styledComponents';

const SideNavItems = observer((props) => {
  const { userStore, uiStore } = useInjectStores();
  const location = useLocation();
  const showSidebar = uiStore.showSidebar,
    toggleSidebar = uiStore.toggleSidebar,
    currentModule = uiStore.currentModule,
    setCurrentModule = uiStore.setCurrentModule;

  const modules = _get(userStore, 'user.currentClient.modules', []);
  const permissions = _get(userStore, 'user.currentClient.permissions', []);
  const userRole = _get(userStore, 'user.userRole', '');
  const accounting = _get(modules, 'accounting'),
    administration = _get(modules, 'administration'),
    clientAdministration = _get(modules, 'clientAdministration'),
    dashboard = _get(modules, 'dashboard'),
    opportunity = _get(modules, 'opportunity'),
    processing = _get(modules, 'processing'),
    transactions = _get(modules, 'transactions'),
    fees = _get(modules, 'fees'),
    tax = _get(modules, 'tax'),
    reports = _get(modules, 'reports'),
    opp = _get(modules, 'opp');

  const iso = {
    display: 'ISO',
    views: {
      commisions: {
        display: 'Commissions',
        route: '/iso/commissions',
        hide_sidebar: false,
        hide_sidebar_dev: false,
      },
      profitLoss: {
        display: 'Profit/Loss by MID',
        route: '/iso/profit-loss',
        hide_sidebar: false,
        hide_sidebar_dev: false,
      },
      passThroughLosses: {
        display: 'Pass-Through Losses',
        route: '/iso/pass-through-losses',
        hide_sidebar: false,
        hide_sidebar_dev: false,
      },
      bankRevenueRequest: {
        display: 'Bank Revenue Request',
        route: '/iso/bank-revenue-request',
        hide_sidebar: false,
        hide_sidebar_dev: false,
      },
      journals: {
        display: 'Journals',
        route: '/iso/journals',
        hide_sidebar: false,
        hide_sidebar_dev: false,
      },
    },
  };
  const store = useLocalObservable(() => ({
    get accountingViews() {
      const entries = Object.entries(accounting.views);
      const newViewOrder = [];

      let order = ['journals', 'accountingDailyBatches', 'dailyDeposits', 'accountingPrepaidBilling', 'tsysStatement'];

      entries.map((view) => {
        if (!_get(view[1], 'hide_sidebar_dev')) {
          order.map((viewOrder, index) => {
            if (view[0] === viewOrder) {
              newViewOrder[index] = view;
            }
          });
        }
      });
      return newViewOrder;
    },
    get taxViews() {
      const entries = Object.entries(tax.views);
      const newViewOrder = [];
      entries.map((view) => {
        if (view[0] === 'tax') {
          newViewOrder.unshift(view);
        } else if (!_get(view[1], 'hide_sidebar_dev')) {
          newViewOrder.push(view);
        }
      });
      return newViewOrder;
    },
    get administrationViews() {
      return Object.entries(administration.views);
    },
    get isoViews() {
      return Object.entries(iso.views);
    },
    get processingViews() {
      const entries = Object.entries(processing.views);
      const newViewOrder = [];
      entries.map((view) => {
        if (view[0] === 'transactions') {
          newViewOrder.unshift(view);
        }
        if (view[0] === 'declines') {
          newViewOrder[1] = view;
        }
        if (view[0] === 'chargebacks') {
          newViewOrder[2] = view;
        }
      });
      return newViewOrder;
    },
    get reportsViews() {
      return Object.entries(reports.views).sort();
    },
    get opportunityViews() {
      // const entries = Object.entries(opportunity.views)
      // entries.map((view) => {
      //   console.log(view)
      // })
      return Object.entries(opportunity.views);
    },
    get transactionsViews() {
      const entries = Object.entries(transactions.views);

      const newViewOrder = [];

      let order = [
        'reconSummary',
        'transactionsAnalytics',
        'transactionsSales',
        'transactionsDeclines',
        'transactionsRefunds',
        'transactionsChargebacks',
        'voidsTransactions',
      ];

      entries.map((view) => {
        if (!_get(view[1], 'hide_sidebar_dev')) {
          order.map((viewOrder, index) => {
            if (view[0] === viewOrder) {
              // if(view[0] === "transactionsDeclines"){
              //     view[1].display = view[1].display + ' & Opportunities';
              //     view[1].route = view[1].route + '/summary';
              // }
              newViewOrder[index] = view;
            }
          });
        }
      });
      return newViewOrder;
    },
    get feesViews() {
      if (fees.views['feesNotes']) {
        fees.views['feesNotes'].hide_sidebar = true;
        fees.views['feesNotes'].hide_sidebar_dev = true;
      }
      // temporary fix until orderby is added in db
      return Object.entries(fees.views).reverse();
    },
  }));

  const checkActive = (route) => {
    if (route === '/transactions/chargebacks/summary') {
      if (location.pathname === '/transactions/chargebacks') {
        return true;
      } else if (location.pathname === route) {
        return true;
      }
    } else if (
      route.toLowerCase().includes('transactions') ||
      route.toLowerCase().includes('accounting') ||
      route.toLowerCase().includes('iso')
    ) {
      if (route.includes(location.pathname)) {
        return true;
      } else if (location.pathname.substring(0, location.pathname.lastIndexOf('/')).includes(route)) {
        return true;
      } else {
        return false;
      }
    } else if (
      route.toLowerCase().includes('admin/abbreviation') ||
      route.toLowerCase().includes('admin/pricing') ||
      route.toLowerCase().includes('opportunity') ||
      route.toLowerCase().includes('report')
    ) {
      if (location.pathname === route) {
        return true;
      } else {
        return false;
      }
    } else {
      if (location.pathname === route) {
        return true;
      } else if (location.pathname.substring(0, location.pathname.lastIndexOf('/')).includes(route)) {
        return true;
      } else if (
        route.substring(0, route.lastIndexOf('/')) === location.pathname.substring(0, location.pathname.lastIndexOf('/'))
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  /*TODO take care of views we don't want to show on the server, rename styles*/
  function Views({ views, desc, skip }) {
    return (
      <Fragment>
        {views.map((viewArr, index) => {
          const view = viewArr[1];
          if (view.display === 'Tsys Statement') {
            view.display = 'TSYS Statement';
          }
          return (
            <Fragment key={`${index}${view.display}`}>
              {!_get(view, 'hide_sidebar_dev') && (
                <SortableLinkItem
                  test={`route: ${view.route} --- pathname: ${location.pathname}`}
                  activeLink={location.pathname === view.route}
                  // onClick={() => setView(view.path)}
                  key={`${index}${view.viewName}`}
                  show={currentModule === desc}
                >
                  <Link className={view.route === '/g8/reserves' ? 'disabledLink' : ''} to={view.route}>
                    <SubItem
                      style={{ fontSize: view.name === 'financial kyc' ? '12px' : '14px' }}
                      activeLink={checkActive(view.route)}
                      // activeLink={currentModule === desc}
                      show={currentModule === desc}
                    >
                      <div>{view.display}</div>
                    </SubItem>
                  </Link>
                </SortableLinkItem>
              )}
            </Fragment>
          );
        })}
      </Fragment>
    );
  }

  return (
    <Wrapper>
      <div className="topItems">
        {showSidebar && (
          <div className="arrowLeft" onClick={toggleSidebar}>
            <ArrowCircleLeftIcon style={{ width: 20, height: 60, paddingRight: 6, color: '#fff' }} />
          </div>
        )}
        {dashboard && dashboard.views && (
          <Item key={'Dashboard'} onClick={() => setCurrentModule('dashboard')}>
            <Link to={`/dashboard`}>
              <Label active={currentModule === 'dashboard'}>DASHBOARD</Label>
            </Link>
          </Item>
        )}
      </div>
      {/* NEW OPP SECTION */}
      <Fragment>
        {opportunity && opportunity.views && (
          <Item key={opportunity.display}>
            <Label active={currentModule === opportunity.display} onClick={() => setCurrentModule(opportunity.display)}>
              {_toUpper('opportunity')}
            </Label>
            <SortableListWrapper
              height={`${
                _reduce(
                  Object.entries(opportunity.views).map((viewArr) => (_get(viewArr[1], 'hide_sidebar') ? 0 : 1)),
                  function (sum, n) {
                    return sum + n;
                  }
                ) *
                  42 -
                4
              }px`}
              show={currentModule === opportunity.display}
            >
              <Views views={store.opportunityViews} desc={opportunity.display} />
            </SortableListWrapper>
          </Item>
        )}
      </Fragment>

      <Fragment>
        {accounting && accounting.views && (
          <Item key={accounting.display}>
            <Label active={currentModule === accounting.display} onClick={() => setCurrentModule(accounting.display)}>
              {_toUpper(accounting.display)}
            </Label>
            <SortableListWrapper
              height={`${
                _reduce(
                  Object.entries(accounting.views).map((viewArr) => (_get(viewArr[1], 'hide_sidebar_dev') ? 0 : 1)),
                  function (sum, n) {
                    return sum + n;
                  }
                ) *
                  42 -
                4
              }px`}
              show={currentModule === accounting.display}
            >
              <Views views={store.accountingViews} desc={accounting.display} />
            </SortableListWrapper>
          </Item>
        )}
      </Fragment>
      <Observer>
        {() => (
          // txns, declines, chargebacks, reserves (with placeholder if they don't have any)
          <Fragment>
            {transactions && transactions.views && (
              <Item key={transactions.display}>
                <Label active={currentModule === transactions.display} onClick={() => setCurrentModule(transactions.display)}>
                  {_toUpper('Transactions')}
                </Label>
                <SortableListWrapper
                  // height={`${(Object.entries(processing.views).length - 2) * 42}px`}
                  height={`${7 * 42 - 10}px`}
                  show={currentModule === transactions.display}
                >
                  {/*<Views views={store.processingViews} desc={processing.display}/>*/}
                  <Views
                    // active={currentModule === transactions.display}
                    views={store.transactionsViews}
                  />
                </SortableListWrapper>
              </Item>
            )}
          </Fragment>
        )}
      </Observer>
      {fees && fees.views && (
        <Observer>
          {() => (
            // Coming Soon when clicked on or hovered over.
            <Fragment>
              <Item>
                <Label onClick={() => setCurrentModule(fees.display)} active={currentModule === fees.display}>
                  FEES
                </Label>
                <SortableListWrapper
                  height={`${
                    _reduce(
                      Object.entries(fees.views).map((viewArr) => (_get(viewArr[1], 'hide_sidebar_dev') ? 0 : 1)),
                      function (sum, n) {
                        return sum + n;
                      }
                    ) *
                      42 -
                    4
                  }px`}
                  show={currentModule === fees.display}
                >
                  <Views
                    // active={currentModule === fees.display}
                    views={store.feesViews}
                  />
                </SortableListWrapper>
              </Item>
            </Fragment>
          )}
        </Observer>
      )}

      {/* {opportunity && opportunity.views && (
                <Observer>
                    {() => (
                        // Coming Soon when clicked on or hovered over.
                        <Fragment>
                            <Item>
                                <Label onClick={() => setCurrentModule(opportunity.display)}
                                       active={currentModule === opportunity.display}>OPPORTUNITY</Label>

                                <SortableListWrapper height={'80px'} show={currentModule === opportunity.display}>
                                    <Views
                                        views={store.opportunityViews} desc={opportunity.display}
                                    />
                                </SortableListWrapper>
                            </Item>
                        </Fragment>
                    )}
                </Observer>
            )} */}
      <div className="topItems">
        <Observer>
          {() => (
            // Coming Soon when clicked on or hovered over.

            <Item key={'FinancialKyc'} onClick={() => setCurrentModule('financialkyc')}>
              <Link to={`/financialkyc`}>
                <Label active={currentModule === 'financialkyc'}>FINANCIAL KYC</Label>
              </Link>
            </Item>

            // <Item onClick={() => setCurrentModule('financialkyc')}>
            //     <Label active={currentModule === 'financialkyc'}>FINANCIAL KYC</Label>
            //     <SortableListWrapper height={'42px'} show={currentModule === 'financialkyc'}>
            //         <Views
            //             views={[
            //                 [
            //                     'finiancialkyc',
            //                     {
            //                         route: `financialkyc`,
            //                         name: 'financial kyc',
            //                         display: 'Know Your Customer Financially',
            //                     },
            //                 ],
            //             ]}
            //             desc={'financial kyc'}
            //         />
            //     </SortableListWrapper>
            // </Item>
          )}
        </Observer>

        <Observer>
          {() => (
            <Fragment>
              <Item key={'reports'} onClick={() => setCurrentModule('reports')}>
                <Link to={`/report/my-reports`}>
                  <Label active={location.pathname.includes('/report/')}>REPORTS</Label>
                </Link>
              </Item>
              {/* {reports && reports.views && (
                                <Item key={reports.display}>
                                    <Label active={currentModule === reports.display}
                                           onClick={() => setCurrentModule(reports.display)}>
                                        {_toUpper(reports.display)}
                                    </Label>
                                    <SortableListWrapper
                                        height={`${(_reduce(Object.entries(reports.views).map((viewArr) => _get(viewArr[1], 'hide_sidebar_dev') ? 0 : 1), function (sum, n) {
                                            return sum + n;
                                        })) * 42 - 4}px`}
                                        show={currentModule === reports.display}
                                    >
                                        <Views skip={['Fulfillment Reports Ship']} views={store.reportsViews}
                                               desc={reports.display}/>
                                    </SortableListWrapper>
                                </Item>
                            )} */}
            </Fragment>
          )}
        </Observer>
        <Fragment>
          {tax && tax.views && (
            <Item key={tax.display}>
              <Label active={currentModule === tax.display} onClick={() => setCurrentModule(tax.display)}>
                {_toUpper(tax.display)}
              </Label>
              <SortableListWrapper
                height={`${
                  _reduce(
                    Object.entries(tax.views).map((viewArr) => (_get(viewArr[1], 'hide_sidebar_dev') ? 0 : 1)),
                    function (sum, n) {
                      return sum + n;
                    }
                  ) *
                    42 -
                  4
                }px`}
                show={currentModule === tax.display}
              >
                <Views views={store.taxViews} desc={tax.display} />
              </SortableListWrapper>
            </Item>
          )}
        </Fragment>
        {userRole === 'Eight' || userRole === 'ISO' ? (
          <Observer>
            {() => (
              <Fragment>
                <Item key={iso.display}>
                  <Label active={currentModule === iso.display} onClick={() => setCurrentModule(iso.display)}>
                    {_toUpper(iso.display)}
                  </Label>
                  <SortableListWrapper
                    height={`${Object.entries(iso.views).filter((arr) => !arr[1].hide_sidebar).length * 40}px`}
                    show={currentModule === iso.display}
                  >
                    <Views views={store.isoViews} desc={iso.display} />
                  </SortableListWrapper>
                </Item>
              </Fragment>
            )}
          </Observer>
        ) : null}
        <Observer>
          {() => (
            <Fragment>
              {administration && administration.views && (
                <Item key={administration.display}>
                  <Label
                    active={currentModule === administration.display}
                    onClick={() => setCurrentModule(administration.display)}
                  >
                    {_toUpper(administration.display)}
                  </Label>
                  <SortableListWrapper
                    height={`${Object.entries(administration.views).filter((arr) => !arr[1].hide_sidebar).length * 40}px`}
                    show={currentModule === administration.display}
                  >
                    <Views views={store.administrationViews} desc={administration.display} />
                  </SortableListWrapper>
                </Item>
              )}
            </Fragment>
          )}
        </Observer>
        <Observer>
          {() => (
            <Fragment>
              {administration && administration.views && (
                <Item key={'Developer'}>
                  <Label active={currentModule === 'Developer'} onClick={() => setCurrentModule('Developer')}>
                    {_toUpper('Developer')}
                  </Label>
                  <SortableListWrapper height={'40px'} show={currentModule === 'Developer'}>
                    <Fragment>
                      <Fragment key={'1_Developer'}>
                        <SortableLinkItem
                          activeLink={location.pathname === 'Developer'}
                          key={`01_Api_Docs`}
                          show={currentModule === 'API Docs'}
                        >
                          <a href="/API_EIGHT_Tech_v81.pdf" target="_blank" rel="noopener noreferrer">
                            <SubItem activeLink={checkActive('/developer/api-docs')} show={currentModule === 'API Docs'}>
                              <div>
                                {'API Docs'} <LinkExternalIcon width="20" />{' '}
                              </div>
                            </SubItem>
                          </a>
                        </SortableLinkItem>
                      </Fragment>
                    </Fragment>
                  </SortableListWrapper>
                </Item>
              )}
            </Fragment>
          )}
        </Observer>
        <div className="topItems">
          <Item key={'contactUs'} onClick={() => setCurrentModule('contactUs')}>
            <Link to={`/contact-us`}>
              <Label active={currentModule === 'contactUs'}>CONTACT US</Label>
            </Link>
          </Item>
        </div>
        {/*<Observer>
          {() => (
            <Fragment>
                {clientAdmin && clientAdmin.moduleDesc && (
                    <Item>
                      <Link to={`/clientAdmin/users`}>
                        <Label>CLIENT ADMIN</Label>
                      </Link>
                    </Item>
                  )}
            </Fragment>
          )}
        </Observer>*/}
      </div>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  overflow: auto;
  height: calc(100% - 92px);
  margin-top: 0;
  margin-left: 4px;

  .arrowLeft {
    display: none;
    position: absolute;
    right: 5px;
    cursor: pointer;
  }

  .innerContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
  }
`;

export default SideNavItems;
