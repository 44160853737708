import React, { useEffect } from 'react';
import { LoadingSpinner, Error } from 'components/UI';
import styled from 'styled-components';
import { Observer, observer, useLocalObservable } from 'mobx-react-lite';
import { _get, _includes, _toNumber, _toPairs, useInjectStores } from 'utils/utils';
import { useLocation, Link, useRouteMatch } from 'react-router-dom';

const OppTabs = observer((props) => {
  const { filterStore, utilsStore } = useInjectStores();
  const oppFilter = filterStore.opportunityFilter;
  const toCurrency = utilsStore.toCurrency;
  const location = useLocation();
  const { url } = useRouteMatch();
  const store = useLocalObservable(() => ({
    widgetData: null,
    currentLocalFilter: '',
    salesOrdCnt: 0,
    salesOrdAmt: 0,
    invalidCardCnt: 0,
    invalidCardAmt: 0,
    cardGoneBadCnt: 0,
    cardGoneBadAmt: 0,
    soCancelbyclientCnt: 0,
    soCancelbyclientAmt: 0,
    netSalesOrdCnt: 0,
    netSalesOrdAmt: 0,
    succCnt: 0,
    succAmt: 0,
    succCntRatio: 0,
    succAmtRatio: 0,
    netFundsRecovAmt: 0,
    setStatus(status) {
      this.status = status;
    },
    setData(data) {
      this.widgetData = data.values[0];
    },
    setParams() {
      this.salesOrdCnt = this.widgetData.sales_ord_cnt;
      this.salesOrdAmt = this.widgetData.sales_ord_amt;
      this.invalidCardCnt = this.widgetData.invalid_card_amt;
      this.invalidCardAmt = this.widgetData.invalid_card_cnt;
      this.cardGoneBadCnt = this.widgetData.card_gone_bad_cnt;
      this.cardGoneBadAmt = this.widgetData.card_gone_bad_amt;
      this.soCancelbyclientCnt = this.widgetData.so_cancelbyclient_cnt;
      this.soCancelbyclientAmt = this.widgetData.so_cancelbyclient_amt;
      this.netSalesOrdCnt = this.widgetData.net_sales_ord_cnt;
      this.netSalesOrdAmt = this.widgetData.net_sales_ord_amt;
      this.succCnt = this.widgetData.succ_cnt;
      this.succAmt = this.widgetData.succ_amt;
      this.succCntRatio = this.widgetData.succ_cnt_ratio;
      this.succAmtRatio = this.widgetData.succ_amt_ratio;
      this.netFundsRecovAmt = this.widgetData.net_funds_recov_amt;
    },
  }));

  useEffect(() => {
    if (props.data) {
      store.setData(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    store.setStatus(props.status);
  }, [props.status]);

  useEffect(() => {
    if (props.data) {
      store.setParams(props.data);
    }
  }, [props.data]);

  return (
    <Observer>
      {() => (
        <>
          {store.status === 'loading' ? (
            <LoadingSpinner size={'200px'} />
          ) : store.status === 'error' ? (
            <Error error={props.error} />
          ) : (
            <div className="tabWrapper">
              <Link
                className={`tab one ${
                  location.pathname.includes('recovery') &&
                  !location.pathname.includes('bad-cards') &&
                  !location.pathname.includes('cancelled') &&
                  !location.pathname.includes('successful')
                    ? 'active'
                    : ''
                }`}
                to={`${url}`}
              >
                {console.log(store.salesOrdCnt, toCurrency(store.salesOrdAmt))}
                <span className="val">Total Transactions</span>
                <span className="val">{store.salesOrdCnt}</span>
                <span className="val">{toCurrency(store.salesOrdAmt)}</span>
              </Link>
              <Link className={`tab two ${location.pathname.includes('bad-cards') ? 'active' : ''}`} to={`${url}/bad-cards`}>
                <span className="val">Cards Gone Bad</span>
                <span className="val">{store.cardGoneBadCnt}</span>
                <span className="val">{toCurrency(store.cardGoneBadAmt)}</span>
              </Link>
              <Link className={`tab three ${location.pathname.includes('cancelled') ? 'active' : ''}`} to={`${url}/cancelled`}>
                <span className="val">Cancelled Sales Orders</span>
                <span className="val">{store.soCancelbyclientCnt}</span>
                <span className="val">{toCurrency(store.soCancelbyclientAmt)}</span>
              </Link>
              <Link className={`tab four ${location.pathname.includes('successful') ? 'active' : ''}`} to={`${url}/successful`}>
                <span className="val">Successful Recovery</span>
                <span className="val">{store.succCnt}</span>
                <span className="val">{toCurrency(store.succAmt)}</span>
              </Link>
            </div>
          )}
        </>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 317px;
  .center {
    color: ${({ theme }) => theme.colors.text};
    margin: auto;
  }
`;

export default OppTabs;
