import React, { useEffect } from 'react';
import { Observer, observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { _get, useInjectStores, useCheckAuthorizedRoute, useGetWidgetData, getFileName } from 'utils/utils';
import { widgetService } from 'services';
import { RouteLoadingSpinner, LoadingSpinner, Error } from 'components/UI';
import ReactGA from 'react-ga';
import { Link, Route, useLocation, useRouteMatch } from 'react-router-dom';
import { toCurrency } from 'utils/currencyFilter';
import OppTabs from './tabs';
import TotalTxnsTable from './totalTxnsTable';
import BadCardsTable from './badCardsTable';
import CancelledCardsTable from './cancelledTable';
import SuccessfulCardsTable from './successfulTable';
const initialData = [];

const Recovery = observer(() => {
  const { loading, authorized, error } = useCheckAuthorizedRoute();
  const { widgetStore, dataManagementStore, uiStore, userStore, utilsStore } = useInjectStores();
  const store = useLocalStore(() => ({
    loading: false,
    widgetData: initialData,
  }));
  const {
    status: statusOppRecovery,
    data: dataOppRecovery,
    error: errOppRecovery,
  } = useGetWidgetData({ name: 'oppRecovery', query: widgetService.oppRecovery }, 'data', dataManagementStore?.newQuery);
  const location = useLocation();
  return (
    <Observer>
      {() => (
        <>
          {loading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : authorized ? (
            <div style={{ height: '100%', width: '100%' }}>
              <Wrapper>
                <div className="tabs">
                  <OppTabs
                    location={location}
                    status={statusOppRecovery}
                    data={dataOppRecovery}
                    error={errOppRecovery}
                    store={store}
                  />
                </div>
                <div className="content">
                  <Route exact path={`/opportunity/recovery`}>
                    <TotalTxnsTable/>
                  </Route>
                  <Route exact path={`/opportunity/recovery/bad-cards`}>
                    <BadCardsTable />
                  </Route>
                  <Route exact path={`/opportunity/recovery/cancelled`}>
                    <CancelledCardsTable/>
                  </Route>
                  <Route exact path={`/opportunity/recovery/successful`}>
                    <SuccessfulCardsTable/>
                  </Route>
                </div>
              </Wrapper>
            </div>
          ) : (
            <div>error</div>
          )}
        </>
      )}
    </Observer>
  );
});

export default Recovery;

const Wrapper = styled.div`
  height: 100%;
  flex-direction: column;
  padding: 0 5px 5px 5px;
  .tabs {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .val {
    padding: 7px;
  }

  .tableWrapper {
    padding-left: 5px;
    height: 100%;
  }

  .content {
    height: 100%;
    width: 100%;
    grid-area: content;
  }

  grid-template-areas:
    'tabs'
    'content';
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  grid-gap: 5px;

  .widget {
    margin: 0 3px;
  }

  .tabWrapper {
    display: flex;
    justify-content: space-evenly;
    grid-template-columns: minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) 1fr;
    grid-gap: 5px;
    height: 100%;
    grid-template-areas: 'one two three four';

    .one {
      grid-area: one;
    }

    .two {
      grid-area: two;
    }

    .three {
      grid-area: three;
    }

    .four {
      grid-area: four;
    }

    .btn {
      cursor: pointer;
    }

    &.active {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }

    .tab {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.text};
      background: rgba(0, 0, 0, 0.6);
      border-radius: 20px;
      width: 20%;
      cursor: pointer;
      font-size: 18px;
      overflow: hidden;
      padding: 4px;
      margin: 10px 0px 10px 0px;
      position: relative;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
      white-space: nowrap;

      &.active {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.7);
      }

      &:hover {
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
`;
