import React, { Fragment, useEffect } from 'react';
import { observer, Observer, useLocalObservable } from 'mobx-react-lite';
import { Dropdown, LoadingSpinner, Error } from 'components/UI';
import {
  ClearIcon,
  Divider,
  FilterIcon,
  HeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableWrapper,
} from 'styles/styledComponents';
import Moment from "moment/moment";
import { _get, _includes, _toNumber, _toPairs, useInjectStores } from 'utils/utils';

const { default: styled } = require('styled-components');

const OppHeaders = observer((props) => {
  const { filterStore, utilsStore, widgetStore } = useInjectStores();

  const widgetModel = widgetStore.cardExpHistDetails;

  const store = useLocalObservable(() => ({
    widgetData: null,
    widgetModel,
    historicData: null,
    currentLocalFilter: '',
    selectedOption: widgetModel.date,
    get historicDate() {
      return store.selectedOption;
    },
    selectOption(val) {
      this.selectedOption = val;
      widgetModel.setDate(val);
    },
    get dropdownOptions() {
      if (this.historicData?.length > 0) {
        return this.historicData.map(({ txn_dt_eom }, index) => {
          return {
            key: index,
            text: `${Moment(txn_dt_eom).format('YYYY')}: ${Moment(txn_dt_eom).format('MMMM')}`,
            value: txn_dt_eom,
          };
        });
      }
      return [];
    },
    setStatus(status) {
      this.status = status;
    },
    setData(data) {
      // this.widgetData = data.dates;
      this.historicData = data.dates;
      // console.log(this.historicData)
    },
    // setParams() {
    //   this.dates = this.widgetData.d
    // },
  }));

  useEffect(() => {
    if (props.data) {
      store.setData(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    store.setStatus(props.status);
  }, [props.status]);

  // useEffect(() => {
  //   if (props.data) {
  //     store.setParams(props.data);
  //   }
  // }, [props.data]);
  return (
    <Observer>
      {() => (
        <Wrapper margin={props.margin}>
          <>
            {store.status === 'loading' ? (
              <LoadingSpinner size={'200px'} />
            ) : store.status === 'error' ? (
              <Error error={props.error} />
            ) : (
              <TableWrapper height="100%">
                {/* <Dropdown
                  id="historical"
                  options={store.dropdownOptions}
                  value={widgetModel.date}
                  onChange={store.selectOption}
                  placeholder="Select Month"
                  width={'140px'}
                  height={'20px'}
                /> */}
                <TableHeader>-</TableHeader>


                <Divider />

                <Table>
                  <TableBody>
                    <Fragment>
                      <TableRow>
                        {/*<HeaderCell />*/}
                        <HeaderCell>.</HeaderCell>
                        <HeaderCell>.</HeaderCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Recoverable Sales Orders:</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>On Invalid Cards:</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Cards Gone Bad in Recovery:</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Sales Orders Cancelled by Client:</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Net Recoverable Sales Orders:</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Successfully Recovered:</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Success Ratio:</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Net Funds Recovered:</TableCell>
                      </TableRow>
                    </Fragment>
                  </TableBody>
                </Table>
              </TableWrapper>
            )}
          </>
        </Wrapper>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 317px;
  .center {
    color: ${({ theme }) => theme.colors.text};
    margin: auto;
  }
`;

export default OppHeaders;
