import React, { useEffect } from 'react';
import { observer, Observer, useLocalStore } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { Notes, Table } from 'components/UI';
// import { initialData } from './resources';
import { autorun } from 'mobx';
import { useHistory, useLocation } from 'react-router-dom';
import { _get, useInjectStores, buildColDef, useGetWidgetData, getFileName } from 'utils/utils';
import { TableHeader, TableWrapper, TableHeaderButton } from 'styles/styledComponents';
import { widgetService } from 'services';
import OppsTotal from './total';
import Insufficient from './insufficient';
import Fixable from './fixable';
import Retry from './retry';
import DNH from './dnh';
import OppDetailsTable from './table'
const initialData = [];

const Opps = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const { widgetStore, dataManagementStore, uiStore, userStore, utilsStore } = useInjectStores();
  const widgetModel = widgetStore.ssTxnsWidgetModel,
    toggleShowTxnDetailModal = uiStore.toggleShowTxnDetailModal,
    setTxnDetails = dataManagementStore.setTxnDetails,
    paginatorModel = widgetModel.paginatorModel;

  const orderByOptions = [
    { key: 1, text: 'Cust Name', value: 'custName' },
    { key: 2, text: 'Invoice Num', value: 'invNum' },
    { key: 3, text: 'Txn Type', value: 'txnType' },
    { key: 4, text: 'Txn Status', value: 'txnStatus' },
    { key: 5, text: 'Txn Date', value: 'txnDt' },
  ];

  const store = useLocalStore(() => ({
    fileName: `summary`,
    loading: false,
    widgetData: initialData,
  }));

  const {
    status: statusOppOpportunities,
    data: dataOppOpportunities,
    error: errOppOpportunities,
  } = useGetWidgetData(
    { name: 'oppOpportunities', query: widgetService.oppOpportunities },
    'data',
    dataManagementStore?.newQuery
  );

  return (
    <Observer>
      {() => (
        <>
          <Wrapper>
            <Wrapper1>
              <div className="section1">
                <OppsTotal
                  status={statusOppOpportunities}
                  data={dataOppOpportunities}
                  error={errOppOpportunities}
                  store={store}
                />
              </div>
              <div className="section2">
                <Insufficient
                  status={statusOppOpportunities}
                  data={dataOppOpportunities}
                  error={errOppOpportunities}
                  store={store}
                />
              </div>
              <div className="section3">
                <Fixable status={statusOppOpportunities} data={dataOppOpportunities} error={errOppOpportunities} store={store} />
              </div>
              <div className="section4">
                <Retry status={statusOppOpportunities} data={dataOppOpportunities} error={errOppOpportunities} store={store} />
              </div>
              <div className="section5">
                <DNH status={statusOppOpportunities} data={dataOppOpportunities} error={errOppOpportunities} store={store} />
              </div>
            </Wrapper1>

            <div className="section6">
            <OppDetailsTable/>
            </div>
          </Wrapper>
        </>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  .middle {
    height:;
    grid-area: middle;
  }

  .note {
    grid-area: note;
  }

  display: grid;
  grid-template-areas:
    'middle'
    'note';
  grid-template-columns 1fr;
  grid-template-rows; auto 1fr auto;
  height: 100%;

  @media (max-width: 1245px) {
    grid-template-areas:
      'section1' 'section2' 'section3' 'section4' 'section5'
      'section6';
    grid-template-columns: 1fr;
  }
`;

const Wrapper1 = styled.div`
  .section1 {
    grid-area: section1;
    height: 100%;
  }

  .section2 {
    grid-area: section2;
    height: 100%;
  }

  .section3 {
    grid-area: section3;
    height: 100%;
  }

  .section4 {
    grid-area: section4;
    height: 100%;
  }

  .section5 {
    grid-area: section5;
    height: 100%;
  }
  .section6 {
    background: blue;
    grid-area: section6;
    height: 100%;
  }

  display: grid;
  grid-template-areas:
    'section1 section2 section3 section4 section5'
    'section6 section6 section6 section6 section6';
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  grid-gap: 0px 2px;

  @media (max-width: 1245px) {
    grid-template-areas:
      'section1'
      'section2'
      'section3'
      'section4'
      'section5'
      'section6';
    grid-template-columns: 1fr;
  }
`;

export default Opps;
