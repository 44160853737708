import React, { Fragment, useEffect } from 'react';
import { Button, LoadingSpinner, Error } from 'components/UI';
import styled from 'styled-components';
import { Observer, observer, useLocalObservable } from 'mobx-react-lite';
import { AgGridReact } from 'ag-grid-react';
import {
  ClearIcon,
  Divider,
  FilterIcon,
  HeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableWrapper,
} from 'styles/styledComponents';
import { autorun } from 'mobx';
import { _get, _includes, _toNumber, _toPairs, useInjectStores } from 'utils/utils';
import { useHistory, useLocation } from 'react-router-dom';

const EightRecovery = observer((props) => {
  const { filterStore, utilsStore } = useInjectStores();
  const oppFilter = filterStore.opportunityFilter;
  const toCurrency = utilsStore.toCurrency;
  const store = useLocalObservable(() => ({
    widgetData: null,
    currentLocalFilter: '',
    salesOrdCnt: 0,
    salesOrdAmt: 0,
    invalidCardCnt: 0,
    invalidCardAmt: 0,
    cardGoneBadCnt: 0,
    cardGoneBadAmt: 0,
    soCancelbyclientCnt: 0,
    soCancelbyclientAmt: 0,
    netSalesOrdCnt: 0,
    netSalesOrdAmt: 0,
    succCnt: 0,
    succAmt: 0,
    succCntRatio: 0,
    succAmtRatio: 0,
    netFundsRecovAmt: 0,
    setStatus(status) {
      this.status = status;
    },
    setData(data) {
      this.widgetData = data.values[0];
    },
    setParams() {
      this.salesOrdCnt = this.widgetData.sales_ord_cnt;
      this.salesOrdAmt = this.widgetData.sales_ord_amt;
      this.invalidCardCnt = this.widgetData.invalid_card_amt;
      this.invalidCardAmt = this.widgetData.invalid_card_cnt;
      this.cardGoneBadCnt = this.widgetData.card_gone_bad_cnt;
      this.cardGoneBadAmt = this.widgetData.card_gone_bad_amt;
      this.soCancelbyclientCnt = this.widgetData.so_cancelbyclient_cnt;
      this.soCancelbyclientAmt = this.widgetData.so_cancelbyclient_amt;
      this.netSalesOrdCnt = this.widgetData.net_sales_ord_cnt;
      this.netSalesOrdAmt = this.widgetData.net_sales_ord_amt;
      this.succCnt = this.widgetData.succ_cnt;
      this.succAmt = this.widgetData.succ_amt;
      this.succCntRatio = this.widgetData.succ_cnt_ratio;
      this.succAmtRatio = this.widgetData.succ_amt_ratio;
      this.netFundsRecovAmt = this.widgetData.net_funds_recov_amt;
    },
  }));

  useEffect(() => {
    if (props.data) {
      store.setData(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    store.setStatus(props.status);
  }, [props.status]);

  useEffect(() => {
    if (props.data) {
      store.setParams(props.data);
    }
  }, [props.data]);

  return (
    <Observer>
      {() => (
        <Wrapper margin={props.margin}>
          <>
            {store.status === 'loading' ? (
              <LoadingSpinner size={'200px'} />
            ) : store.status === 'error' ? (
              <Error error={props.error} />
            ) : (
              <TableWrapper height="100%">
                <TableHeader>EIGHT TECH RECOVERY SERVICES</TableHeader>

                <Divider />

                <Table>
                  <TableBody>
                    <Fragment>
                      <TableRow>
                        <HeaderCell>Count</HeaderCell>
                        <HeaderCell>Amount</HeaderCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{store.salesOrdCnt}</TableCell>
                        <TableCell>{toCurrency(store.salesOrdAmt)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{store.invalidCardCnt}</TableCell>
                        <TableCell>{toCurrency(store.invalidCardAmt)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{store.cardGoneBadCnt}</TableCell>
                        <TableCell>{toCurrency(store.cardGoneBadAmt)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{store.soCancelbyclientCnt}</TableCell>
                        <TableCell>{toCurrency(store.soCancelbyclientAmt)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{store.netSalesOrdCnt}</TableCell>
                        <TableCell>{toCurrency(store.netSalesOrdAmt)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{store.succCnt}</TableCell>
                        <TableCell>{toCurrency(store.succAmt)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{store.succCntRatio}</TableCell>
                        <TableCell>{toCurrency(store.succAmtRatio)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>-</TableCell>
                        <TableCell>{toCurrency(store.netFundsRecovAmt)}</TableCell>
                      </TableRow>
                    </Fragment>
                  </TableBody>
                </Table>
              </TableWrapper>
            )}
          </>
        </Wrapper>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 317px;
  .center {
    color: ${({ theme }) => theme.colors.text};
    margin: auto;
  }
`;

export default EightRecovery;
