import React, { useEffect } from 'react';
import SsDeclTotals from '../../components/Widgets/SsDeclTotals/SsDeclTotals';
import {SsOpportunity} from '../../components/Widgets';
import SsDeclTxns from '../../components/Widgets/SsDeclTxns/SsDeclTxns';
import { Observer, observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { useCheckAuthorizedRoute, useInjectStores } from 'utils/utils';
import { RouteLoadingSpinner } from 'components/UI';
import {Link, Route, useLocation, useRouteMatch} from "react-router-dom";
import ReactGA from 'react-ga';

const Declines = observer(() => {
  const { loading, authorized } = useCheckAuthorizedRoute();
  const { userStore } = useInjectStores();
  const user = userStore.user;
  useEffect(() => {
    ReactGA.initialize('UA-195070931-1', {
      gaOptions: {
        clientId: user.currentClient.id,
        clientName: user.currentClient.clientName,
        userEmail: user.email
      },
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  return (
    <Observer>
      {() => (
        <>
          {loading ? (
              <RouteLoadingSpinner size={'400px'}/>
          ) : authorized ? (
            <div style={{ height: '100%', width: '100%' }}>
            <Wrapper>
                  <SsDeclTxns />
            </Wrapper>
          </div>
          ) : (
              <div>error</div>
          )}
        </>
      )}
    </Observer>
  );
});

const Tabs = () => {
  const {url} = useRouteMatch();
  const location = useLocation();
  return (
      <div className="tabWrapper">
          <Link
              className={`tab one ${location.pathname.includes('summary') ? 'active' : ''}`}
              to={`${url}/summary`}>
              Summary
          </Link>
          <Link
              className={`tab two ${location.pathname.includes('declines')
              && !location.pathname.includes('summary')
                  ? 'active' : ''}`}
              to={`${url}`}>
              Details
          </Link>
      </div>
  );
};

const Wrapper = styled.div`
  height: 100%;
  flex-direction: column;
  display: flex;
  padding-bottom: 20px;
  .tableWrapper {
    padding-left:5px;
    height: 100%;
  }
  .tabs {
    grid-area: tabs;
    width: 100%;
    padding-bottom: 5px;
    padding-left:5px
  }

  //grid-template-columns: 1fr;
  //grid-template-rows: auto auto;
  //grid-gap: 5px;
  //grid-template-areas:'totals''details';
  .widget {
    margin: 0 3px;
  }

  .tabWrapper {
    //width: calc(100% - 21px);
    display: grid;
    grid-template-columns: minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) minmax(100px, 200px) 1fr;
    grid-gap: 5px;
    height: 100%;

    grid-template-areas: 'one two';

    .one {
      grid-area: one;
    }
    .two {
      grid-area: two;
    }

    .btn {
      cursor: pointer;
    }

    &.active {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.text};
      background: rgba(0, 0, 0, 0.6);
      border-radius: 2px;
      cursor: pointer;
      font-size: 18px;
      overflow: hidden;
      padding: 4px;
      position: relative;
      text-overflow: ellipsis;
      transition: background-color 0.25s, color 0.25s;
      white-space: nowrap;
      &:hover {
        // background-color: ${(props) => props.theme.baseColors.colorGreyMedium};
        background: rgba(0, 0, 0, 0.4);
      }
      &.active {
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
    }
    .test{
      margin-left: 5px;
    }
  }
  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 400px) {
    .tabs {
      grid-template-columns: 1fr;
    }
  }

`;

export default Declines;
