import React, { useEffect } from 'react';
import { TableWrapper, TableContainer, TableHeader } from 'styles/styledComponents';

import { observer, Observer, useLocalObservable } from 'mobx-react-lite';
const { default: styled } = require('styled-components');

const OppNotes = observer((props) => {
  return (
    <Observer>
      {() => (
        <Wrapper>
          <TableWrapper width="100%">
            <TableHeader color={'#FF2B49'}>NOTES</TableHeader>
            <TableContainer>
              <div className="note " style={{ padding: '0 15px' }}>
                <div
                  css={`
                    grid-area: one;
                    display: flex;
                    gap: 2px;
                  `}
                  className="one spacing"
                >
                  {/* <span>1.</span> */}
                  <p style={{ marginTop: '0px' }}>
                    1. The number of "Recoverable Sales Orders" will not be the same between Eight Tech , Client, and Third Party
                    services.
                  </p>
                  <p style={{ marginTop: '0px' }}>
                    * Eight Tech's systems & intelligence create efficiency that does not try to recovery orders tied to bad &
                    invalid credit/debit cards.
                  </p>
                  <p style={{ marginTop: '0px' }}>
                    Eight Tech's systems also stops the recovery process when a good card turns bad; thus saving you money in
                    unnecessary fees.
                  </p>
                  <p style={{ marginTop: '0px' }}>
                    * Client/Merchant is usually just going after some orders, not all that are recoverable (also, they usually
                    cannot identify invalid cards).
                  </p>
                  <p style={{ marginTop: '0px' }}>
                    * Third Party recovery services have limited access to industry data, & go after whatever the Client sends to
                    them without validating whether the credit/debit card is good or not.
                  </p>
                  <p style={{ marginTop: '0px' }}>
                    Therefore, running invalid cards over & over with no success, but excessive fees for their futile attempts.
                  </p>
                </div>
                <div
                  css={`
                    grid-area: two;
                    display: flex;
                    gap: 2px;
                  `}
                  className="two spacing"
                >
                  {/* <span>2. </span> */}
                  <p style={{ marginTop: '0px' }}>
                    2. Invalid credit & debit cards, cards gone bad during recovery process, & duplicate customers & sales orders
                    are common occurences that are not identified by Clients & Third Party services.
                  </p>
                </div>
                <div
                  css={`
                    grid-area: three;
                    display: flex;
                    gap: 2px;
                  `}
                  className="three spacing"
                >
                  {/* <span>3. </span> */}
                  <p style={{ marginTop: '0px' }}>
                    <span>
                      3. Most third party services do not communicate well with Client, thus continuing recovery efforts on
                      customers &/or sales orders that have been cancelled… leading to potential chargebacks, etc.
                    </span>
                    <span></span>
                  </p>
                </div>
              </div>
              <br />
            </TableContainer>
          </TableWrapper>
        </Wrapper>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  height: auto;

  .note {
    display: grid;
    grid-template-areas:
      'one one one one one'
      'two '
      'three ';
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-gap: 15px 5px;
    font-size: 13px;

    .spacing {
      line-height: 2em;
    }
  }
`;
export default OppNotes;
