import { api } from './api';

export const widgetService = {
  
  oppSummary: (query) => api.get('/v1/opportunity/stats', query),
  oppRecovery: (query) => api.get('/v1/opportunity/recovery', query),
  oppRecoveryTotal: (query) => api.get('/v1/opportunity/recovery/total', query),
  oppRecoveryBadCards: (query) => api.get('/v1/opportunity/recovery/bad-cards', query),
  oppRecoveryCancelled: (query) => api.get('/v1/opportunity/recovery/cancelled', query),
  oppRecoverySuccessful: (query) => api.get('/v1/opportunity/recovery/successful', query),
  oppOpportunities: (query) => api.get('/v1/opportunity/opportunities', query),
  oppOpportunitiesTable: (query) => api.get('/v1/opportunity/opportunities/details', query),


  dashboardAveragesGraph: (query) => api.get('/v1/dashboard/averages', query),
  dashboardTotalGraph: (query) => api.get('/v1/dashboard/totalsGraph', query),
  dashboardStatistics: (query) => api.get('/v1/dashboard/statistics', query),
  // dashboardTotals: (query) => api.get('/v1/dashboard/totals', query),
  dashboardProcessingLimit: (query) => api.get('/v1/dashboard/processingLimit', query),
  dashboardProcessingLimitHistory: (query) => api.get('/v1/dashboard/processingLimitHistory', query),
  dashboardAvsIssues: (query) => api.get('/v1/dashboard/avsIssues', query),
  dashboardBumpFeeReason: (query) => api.get('/v1/dashboard/bumpFeeReason', query),
  dashboardChargebackReason: (query) => api.get('/v1/dashboard/chargebackReason', query),
  dashboardDecline: (query) => api.get('/v1/dashboard/decline', query),
  dashboardErrorReason: (query) => api.get('/v1/dashboard/errorReason', query),
  dashboardRejectReason: (query) => api.get('/v1/dashboard/rejectReason', query),
  dashboardReversalReason: (query) => api.get('/v1/dashboard/reversalReason', query),
  dashboardSkimmedCards: (query) => api.get('/v1/dashboard/skimmedCards', query),

  // opportunitySummary: (query) => api.get('/v1/opportunity/summary', query),
  // opportunityRecovery: (query) => api.get('/v1/opportunity/recovery', query),
  // opportunityRecovery: (query) => api.get('/v1/opportunity/recovery', query),
  // opportunityDetails: (query) => api.get('/v1/opportunity/details', query),
  // opportunityGrossData: (query) => api.get('/v1/opportunity/grossData', query),
  // opportunityCollectionsData: (query) => api.get('/v1/opportunity/collectionsData', query),
  // opportunityNetValues: (query) => api.get('/v1/opportunity/netValues', query),
  detailedTransaction: (query) =>
    api.get(
      `/v1/detail/transaction?displayID=${query.displayID}&midNum=${query.mid}&startDt=${query.startDt}&endDt=${query.endDt}&clientID=${query.clientID}&ccBin=${query.ccBin}&ccLast4=${query.ccLast4}`,
      query
    ),
  detailedNotesGet: (query) =>
    api.get(`/v1/detail/notes?uniqTxnID=${query.uniqTxnID}&midNum=${query.midNum}&clientID=${query.clientID}`, query),
  detailedNotesPost: (post) =>
    api.post(
      `/v1/detail/notes?uniqTxnID=${post.uniqTxnID}&midNum=${post.midNum}&clientID=${post.clientID}&note=${post.note}`,
      post
    ),
  transactionPresentedSales: (query) => {
    if (query) return api.get(`/v1/transaction/presentedSales?${query}`, query);
    return api.get(`/v1/transaction/presentedSales`, query);
  },
  transactionUnsuccessfulSales: (query) => api.get('/v1/transaction/unsuccessfulSales', query),
  transactionPresentedReturns: (query) => api.get('/v1/transaction/presentedReturns', query),
  transactionUnsuccessfulReturns: (query) => api.get('/v1/transaction/unsuccessfulReturns', query),
  transactionMerchantFees: (query) => api.get('/v1/transaction/merchantFees', query),
  transactionRecon: (query) => api.get('/v1/transaction/reconciliationAdjustment', query),
  transactionDetails: (query) => api.get('/v1/transaction/details', query),
  declineSummary: (query) => api.get('/v1/decline/summary', query),
  declinePreventable: (query) => api.get('/v1/decline/preventable', query),
  declineDetails: (query) => api.get('/v1/decline/details', query),
  chargebackSummary: (query) => api.get('/v1/chargeback/summary', query),
  chargebackPreventable: (query) => api.get('/v1/chargeback/preventable', query),
  chargebackDetails: (query) => api.get('/v1/chargeback/details', query),
  accountingJournals: (query) => api.get('/v1/accounting/journals', query),
  accountingDailyTransactions: (query) => api.get('/v1/accounting/dailyTransactions', query),
  accountingDailyDeposits: (query) => api.get('/v1/accounting/dailyDeposits', query),
  accountingTsysListStatement: (query) => api.get('/v1/accounting/tsysListStatement', query),
  accountingTsysRawStatement: (query) => api.get('/v1/accounting/tsysRawStatement', query),
  shipSummary: (query) => api.get('/v1/reports/shipSummary', query),
  shipDetails: (query) => api.get('/v1/reports/shipDetails', query),
  noShipSummary: (query) => api.get('/v1/reports/noShipSummary', query),
  noShipDetails: (query) => api.get('/v1/reports/noShipDetails', query),
  reportDNH: (query) => api.get('/v1/reports/doNotHonor', query),
  reportCardExpired: (query) => api.get('/v1/reports/cardExpired', query),
  reportCardExpiredHistoric: (query) => api.get('/v1/reports/cardExpired/historic', query),
  reportTxnByBatchNum: (query) => api.get(`/v1/reports/txnsByBatch`, query),
  reportChargebackAbuse: (query) => api.get('/v1/reports/chgbkAbuse', query),
  reportRefundAbuse: (query) => api.get('/v1/reports/refundAbuse', query),
  reportAvsFailure: (query) => api.get('/v1/reports/avsFailure', query),
  reportLostStolen: (query) => api.get('/v1/reports/lostStolen', query),
  reportInvalidFraud: (query) => api.get('/v1/reports/invalidFraud', query),
  reportPrepaidCard: (query) => api.get('/v1/reports/prepaidCard', query),
  reportOfacBanks: (query) => api.get('/v1/reports/ofacBanks', query),
  reportSuspendedBanks: (query) => api.get('/v1/reports/suspendedBanks', query),
  reportDeclineAnalysis: (query) => api.get('/v1/reports/declineAnalysis', query),
  reportBinAttackFraud: (query) => api.get('/v1/reports/binAttackFraud', query),
  userReports: (query) => api.get('/v1/reports/myReports', query),
  allUserReports: (query) => api.get('/v1/reports/allReports', query),
  isoJournals: (query) => api.get('/v1/iso/journals', query),
  isoPassThroughLosses: (query) => api.get('/v1/iso/pass-through-losses/details', query),
  isoPassThroughLossesMonthly: (query) => api.get('/v1/iso/pass-through-losses/details/monthly', query),
  isoPassThroughLossesSummary: (query) => api.get('/v1/iso/pass-through-losses', query),
  isoPassThroughLossesSummaryMonthly: (query) => api.get('/v1/iso/pass-through-losses/monthly', query),
  isoBankRevenue: (query) => api.get('/v1/iso/bank-revenue-request', query),
  isoBankRevenueMonthly: (query) => api.get('/v1/iso/bank-revenue-request/monthly', query),
  isoCommissionsReport: (query) => api.get('/v1/iso/commissions/report', query),
  isoCommissionsPayout: (query) => api.get('/v1/iso/commissions/payout', query),
  isoCommissionsAgents: (query) => api.get('/v1/iso/commissions/agents', query),
  isoCommissionsAgent: (query) => api.get('/v1/iso/commissions/agent', query),
  isoProfitLoss: (query) => api.get('/v1/iso/profit-loss', query),
  isoProfitLossMonthly: (query) => api.get('/v1/iso/profit-loss/monthly', query),
  isoProfitLossDetails: (query) => api.get('/v1/iso/profit-loss/details', query),

  // newQueryTest: (query) => api.get(`/v1/fees/details?midNum=937500000003285&clientID=298&startTxnFeeDt=2021-08-01T00:00:00&endTxnFeeDt=2021-08-31T23:59:59`),
  //Recon Summary

  analyticsSummary: (query) => api.get(`/v1/transactions/analytics`, query),
  analyticsDetails: (query) => api.get(`/v1/transactions/analytics/details`, query),

  reconSummaryPurchasesSales: (query) => api.get(`/v1/transactions/purchases/sales`, query),
  reconSummaryPurchasesDeclines: (query) => api.get(`/v1/transactions/purchases/declines`, query),
  reconSummaryPurchasesVoids: (query) => api.get(`/v1/transactions/purchases/voids`, query),

  reconSummaryFees: (query) => api.get(`/v1/transactions/fees`, query),

  reconSummaryReturnsSuccessful: (query) => api.get(`/v1/transactions/returns/successful`, query),
  reconSummaryReturnsChargebacks: (query) => api.get(`/v1/transactions/returns/chargebacks`, query),
  reconSummaryReturnsErrors: (query) => api.get(`/v1/transactions/returns/errors`, query),
  reconSummaryReturnsVoids: (query) => api.get(`/v1/transactions/returns/voids`, query),

  adminGetMerchSettings: (query) => api.get(`admin/merchant/settings`, query),

  /*TODO accounting*/
  acctTxns: (query) => api.get(`/v1/accounting/transactions`, query),
  acctTxnsFees: (query) => api.get(`/v1/accounting/fees`, query),
  acctCash: (query) => api.get(`/v1/accounting/cash`, query),
  acctDeposits: (query) => api.get(`/v1/accounting/deposits`, query),
  acctDailyDeposits: (query) => api.get(`/v1/accounting/dailyDeposits`, query),
  acctDailyBatches: (query) => api.get(`/v1/accounting/dailyBatches`, query),
  acctPrepaid: (query) => api.get(`v1/accounting/prepaidBilling`, query),
  //Txns Analytics Summary Page
  analytics1: (query) => api.get(`/v1/transactions/analytics`, query),
  analytics2: (query) => api.get(`/v1/transactions/analytics/details`, query),

  /*TODO Recon Page*/
  //Txns - Recon Summary > Purchases Section > A
  reconA: (query) => api.get(`/v1/transactions/purchases/sales`, query),
  //Txns- Recon Summary > Purchases Section > B
  reconB: (query) => api.get(`/v1/transactions/purchases/declines`, query),
  //Txns - Recon Summary > Purchases Section > C
  reconC: (query) => api.get(`/v1/transactions/purchases/voids`, query),

  //Txns- Recon Summary > Returns Section  > D
  reconD: (query) => api.get(`/v1/transactions/returns/successful`, query),
  //Txns - Recon Summary > Returns Section  > E
  reconE: (query) => api.get(`/v1/transactions/returns/chargebacks`, query),
  //Txns - Recon Summary > Returns Section  > F
  reconF: (query) => api.get(`/v1/transactions/returns/errors`, query),
  //Txns - Recon Summary > Returns Section  > G
  reconG: (query) => api.get(`/v1/transactions/returns/voids`, query),

  //Txns - Recon Summary > fees assessment section
  reconH: (query) => api.get(`/v1/transactions/fees`, query),

  /*TODO sales*/
  //Txns - VoidsInquiries > Main VoidsInquiries Txn Page
  sales1: (query) => api.get(`/v1/transactions/sales`, query),
  //Txns - VoidsInquiries > Details Button Information
  sales2: (query) => api.get(`/v1/transactions/sales/details`, query),

  /*TODO refunds*/
  //Txns VoidsInquiries > Details Button Information
  refunds1: (query) => api.get(`/v1/transactions/refunds`, query),
  refunds2: (query) => api.get(`/v1/transactions/refunds/details`, query),

  /*TODO Declines*/
  //Txns - Declines > Main Declines Txn Page
  declines1: (query) => api.get(`/v1/transactions/declines`, query),
  //Txns - Declines > Details Button Information
  declines2: (query) => api.get(`/v1/transactions/declines/details`, query),

  /*TODO chargebacks*/
  //Txns - Chargebacks > Main Chgbk Txn Page
  chargebacks1: (query) => api.get(`/v1/transactions/chargebacks`, query),
  //Txns - Chargebacks > Details Button Information
  chargebacks2: (query) => api.get(`/v1/transactions/chargebacks/details`, query),
  //Txns - Chargebacks > Summary Page
  chargebacks3: (query) => api.get(`/v1/transactions/chargebacks/summary`, query),

  /*TODO voids & Inquiries*/
  voidsInquiries: (query) => api.get(`/v1/transactions/voids`, query),
  voidsInquiriesDetails: (query) => api.get(`/v1/transactions/voids/details`, query),

  /*TODO Fees*/
  fees: (query) => api.get(`/v1/fees/details`, query),

  /*TODO Opportunity*/
  opptxnA: (query) => api.get(`/v1/opportunity/transactions`, query),
  // opptxnB: (query) => api.get(`/v1/opportunity/transactions/B?midNum=937500000002600&clientID=205&startTxnDt=2022-01-01T00:00:00&endTxnDt=2022-01-31T23:59:59&pageNumber=1&txnLimit=50`),
  // opptxnC: (query) => api.get(`/v1/opportunity/transactions/C?midNum=937500000000646&clientID=60&startTxnDt=2021-08-01T00:00:00&endTxnDt=2021-08-31T23:59:59&pageNumber=1&txnLimit=50`),
  oppA: (query) => api.get(`/v1/transactions/declines/summary`, query),
  // oppB: (query) => api.get(`/v1/transactions/declines/summary`,query),
  oppDetails: (query) => api.get(`/v1/opportunity/details`, query),
  // oppB: (query) => api.get(`/v1/opportunity/summary/B`,query),
  // oppC: (query) => api.get(`/v1/opportunity/summary/C`, query),
  // oppD: (query) => api.get(`/v1/opportunity/summary/D`, query),

  /*TODO Dashboard*/
  dashboardAverages: (query) => api.get(`/v1/dashboard/graphAverages`, query),
  dashboardTrends: (query) => api.get(`/v1/dashboard/graphTrends`, query),
  dashboardGraph: (query) => api.get(`/v1/dashboard/graph`, query),
  dashboardTotals: (query) => api.get(`/v1/dashboard/graphTotals`, query),
  procLimit: (query) => api.get(`/v1/dashboard/limitCurrent`, query),
  procLimitHist: (query) => api.get(`/v1/dashboard/limitHistoric`, query),

  //  TODO Tax
  tax1099: (query) => api.get('/v1/tax/1099-K', query),

  // Archive Data
  setHistoricalData: (query) => api.put(`/v1/data/archive?clientID=${query.clientID}`),
  setCurrentData: (query) => api.put(`/v1/data/current?clientID=${query.clientID}`),
};

export const adminService = {
  adminUsersByClient: (query) => api.get('/v1/admin/usersByClient', query),
  // adminGetUsers: (query) => api.get('/v1/admin/users', query),
  // adminGetUser: (query) => api.get('/v1/admin/user', query),
  // adminCreateUser: (post) => api.post('/v1/admin/user', post),
  adminEditUser: (put) => api.put('/v1/admin/user', put),
  // adminDeleteUser: (del) => api.delete('/v1/admin/user', del),
  adminGetClient: (query) => api.get('/v1/admin/client', query),
  adminGetClients: (query) => api.get('/v1/admin/clients', query),
  adminCreateClient: (post) => api.post('/v1/admin/client', post),
  adminEditClient: (put) => api.put('/v1/admin/client', put),
  adminDeleteClient: (del) => api.delete('/v1/admin/client', del),
  adminGetMid: (query) => api.get('/v1/admin/mid', query),
  adminGetMids: (query) => api.get('/v1/admin/mids', query),
  // adminCreateMid: (post) => api.post('/v1/admin/mid', post),

  // updateMid: (put) => api.put('/admin/mid', put),
  deleteMid: (del) => api.delete('/admin/mid', del),

  // Client
  getAdminData: (query) => api.get('/admin/data', query),
  getClients: (query) => api.get('/admin/clients', query),

  getClient: (query) => api.get(`/admin/client?clientID=${query.clientID}`),
  createClient: (post) => api.post(`/admin/client`, post),
  updateClient: (put) => api.put(`/admin/client`, put),
  deleteClient: (del) => api.delete(`/admin/client`, del),

  createMid: (post) => api.post(`/admin/mid`, post),
  // updateMid: (put) => api.put(`/admin/mid`, put),
  getClientMid: (query) => api.get(`/admin/mid?clientID=${query.clientID}&midNum=${query.midNum}`),
  getClientMids: (query) => api.get(`/admin/mids?clientID=${query.clientID}`),
  getGateways: (query) => api.get('/admin/gateways', query),
  getProcessors: (query) => api.get('/admin/processors', query),
  getIntegrations: (query) => api.get(`/admin/integrations?midNum=${query.midNum}`),

  updateProcLimit: (put) => api.put(`/admin/procLimit`, put),
  createProcLimit: (post) => api.post(`/admin/procLimit`, post),
  getProcLimits: (query) => api.get(`/admin/procLimits?midNum=${query.midNum}`),
  createIntegration: (post) => api.post(`/`, post),

  getUserRoles: (query) => api.get(`/admin/userRoles?clientID=${query.clientID}&email=${query.email}`),

  adminGetUser: (query) => api.get(`/admin/user?email=${query.email}`),
  adminGetUsers: () => api.get(`/admin/users`),
  adminCreateUser: (post) => api.post('/admin/user', post),
  adminUpdateUser: (put) => api.put('/admin/user', put),
  adminDeleteUser: (del) => api.delete('/admin/user', del),

  adminAddUserToClients: (post) => api.post('/admin/clientUser', post),
  deleteUserFromClients: (del) => api.post('/admin/clientUser', del),

  adminAddUserClientRoles: (post) => api.post('/admin/userRoles', post),

  adminDeleteUserClientRoles: (del) => api.delete('/admin/userRoles', del),

  adminGetClientUsers: (query) => api.get(`/admin/clientUsers?clientID=${query.clientID}`),
  adminGetClientsByUser: (query) => api.get(`/admin/clientsByUser?email=${query.email}`),

  clientRemoveUser: (del) => api.delete('/client/user', del),

  // ADMIN USER ROUTES
  adminUserSettings: (query) => api.get(`/admin/user/settings?clientID=${query.clientID}`),
  adminUserEdit: (query) => api.get(`/admin/user/edit?clientID=${query.clientID}&userEmail=${query.userEmail}`),
  adminUserUpdateInfo: (params) => api.put(`/admin/user/edit?${params}`),
  adminDeactivateUser: (params) => api.put(`/admin/user/deactivate?${params}`),
  adminUpdateUserPassword: (params) =>
    api.put(`/admin/user/edit?clientID=${params.clientID}&usersEmail=${params.userEmail}&userPassword=${params.password}`),
  adminUserForm: (query) => api.get(`/admin/user/add?clientID=${query.clientID}&checkEmail=${query.checkEmail}`),
  adminUserCreate: (params) => api.post(`/admin/user/add?${params}`),
  adminUserUpdate: (put) => api.put(`/admin/user/add`, put),

  // ADMIN PRICING ROUTES
  adminGetPricing: (query) => api.get(`admin/pricing?clientID=${query.clientID}`),
  adminUpdatePricing: (params) => api.put(`/admin/pricing/edit?${params}`),

  // MERCHANT ADMIN ROUTES CREATE
  adminGetMerchSettings: (query) => api.get(`admin/merchant/settings?clientID=${query.clientID}`),
  adminGetMerchAdd: (query) => api.get(`admin/merchant/add?clientID=${query.clientID}`),
  adminMerchCreate: (params) => api.post(`admin/merchant/add?${params}`),

  // MERCHANT ADMIN ROUTES UPDATE
  adminGetMerchEdit: (query) => api.get(`admin/merchant/edit?clientID=${query.clientID}&mid=${query.mid}`),
  adminMerchUpdate: (params) => api.put(`admin/merchant/edit?${params}`),

  // ABBRVIATIONS LIST
  adminGetAbbreviations: (query) => api.get(`admin/abbreviation?clientID=${query.clientID}`),
};

export const commonService = {
  /** adminService **/
  // createClient: (post) => api.post('/admin/updateClientDataNew', post),
  // createClientCredentials: (post) => api.post('/eyg/eygClientCredential', post),
  // createClientMidAggr: (post) => api.post('/eyg/eygClientMidAggr', post),
  // createClientMidLimit: (post) => api.post('/eyg/eygClientMidLimit', post),
  // initiateEyghtProcess: (post) => api.post('/transactions/ssEyght/run', post),
  // updateClientMid: (post) => api.put('/eyg/eygClientMid', post),
  // getProcessors: (query) => api.get('/admin/getProcessors', query),
  // getGateways: (query) => api.get('/admin/getGateways', query),
  // getPricingPlans: (query) => api.get('/admin/getPricingPlans', query),
  //
  // /**adminSSMgmtService**/
  // getPymtTypeOptions: (query) => api.get('/gen/genPymtType', query),
  // createAggrTxnTypeMap: (post) => api.post('/transactions/ssTxnType', post),
  //
  // /** commonService **/
  // getWhiteLabelTheme: () => api.get('/theme'),
  // getAppData: (query) => api.get('/app/appData', query),
  //
  // /**symplySyncService**/
  // getWidgetData: (query) => api.post('/widget', query),
  // getTransactionDetailsData: (post) => api.get('/transactions/transactionData', post),
  // getTransactionDetails: (post) => api.get('/transactions/transaction', post),
  //
  // /** User Service **/
  // routeAuthCheck: (query) => api.get('/auth/routeAuthCheck', query),
  // createUser: (post) => api.post('/eyg/eygUser', post),
  // getSessionUser: (query) => api.get('/auth/sessionUser', query),
  // updateUser: (put) => api.put('/eyg/eygUser', put),
  // getAdminData: () => api.post('/admin/adminData'),
  //
  // // NEW
  // getRoles: (post) => api.post('/admin/getRoles', post),
  // getUsers: (post) => api.post('/admin/getUsers', post),
  // getUser: (post) => api.post('/admin/getUser', post),
  // getAggrs: (post) => api.post('/admin/getAggrs', post),
  // getAggr: (post) => api.post('/admin/getAggr', post),
  // getClients: (post) => api.post('/admin/getClients', post),
  // getClient: (post) => api.post('/admin/getClientNew', post),
  // getUserClients: (post) => api.post('/eyg/eygUserClients', post),
  // getUsersByClient: (post) => api.post('/admin/getUsersByClient', post),
  // // NEW
  //
  // login: (post) => api.post('/auth/login', post),
  // logout: (query) => api.get('/auth/logout', query),
  // resetPassword: (post) => api.post('/auth/resetPassword', post),
  // checkResetPasswordHash: (hash) => api.get(`/auth/reset_password/${hash}`),
  // resetPasswordHash: (hash, post) => api.post(`/auth/reset_password/${hash}`, post),
  // testNewCredentials: (post) => api.post(`/admin/testNewCredentials`, post),
  // testExistingCredentials: (post) => api.post(`/admin/testExistingCredentials`, post),

  /** Notes And Action Items* */
  /*  getActionItems: (que  ry) => api.get('/aggr/aggrTxnActionItem', query),
      createActionItems: (post) => api.post('/aggr/aggrTxnActionItem', post),
      updateActionItems: (put) => api.put('/aggr/aggrTxnActionItem', put),
      deleteActionItem: (id) => api.delete('/aggr/aggrTxnActionItem', id),
      getNote: (query) => api.post('/aggr/aggrTxnNote', query),
      createNote: (post) => api.post('/aggr/aggrTxnNote', post),
      updateNote: (put) => api.put('/aggr/aggrTxnNote', put),
      deleteNote: (id) => api.delete('/aggr/aggrTxnNote', id),*/
  login: (post) => api.post('/v1/login', post),
  logout: (post) => api.post('/v1/logout', post),
  resetPasswordGet: (get) => api.get(`/v1/reset_password?hash=${get.hash}`, get),
  resetPasswordPost: (hash, post) => api.post(`/v1/reset_password?hash=${hash}`, post),
  requestResetPassword: (post) => api.post(`/v1/request_reset`, post),
  fetchUserData: (query) => api.get('/v1/session', query),
  changeClient: (query) => api.post('/v1/change_client', query),
  routeAuthCheck: (query) => api.get(`/v1/route_authcheck?clientID=${query.clientID}&route=${query.route}`),
};

export default commonService;
