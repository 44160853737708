import React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { Summary } from '../../components/Widgets';
import { useCheckAuthorizedRoute } from 'utils/utils';
import { RouteLoadingSpinner } from 'components/UI';
import styled from 'styled-components';

const summary = observer(() => {
  let { loading, authorized } = useCheckAuthorizedRoute();
  return (
    <Observer>
      {() => (
        <>
          {loading ? (
            <RouteLoadingSpinner size={'400px'} />
          ) : authorized ? (
            <Wrapper>
              <div className='summary'>
              <Summary />
              </div>
            </Wrapper>
          ) : (
            <Wrapper style={{ width: '99%', margin: '0px 5px' }}>
              <div style={{ color: 'white', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                You are not authorized to view this page.
              </div>
            </Wrapper>
          )}
        </>
      )}
    </Observer>
  );
});



const Wrapper = styled.div`
  height: 100%;
  display: grid;
  flex-direction: column;
  grid-gap: 5px;
  margin: 0 5px 5px 5px;
  .summary{
    grid-area: summary;
  }
  grid-template-areas:
    'summary';
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto);

`;

export default summary;